import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionGroup,
  CollectionReference,
  connectFirestoreEmulator,
  deleteDoc,
  deleteField,
  doc,
  DocumentReference,
  DocumentSnapshot,
  endAt,
  FirestoreDataConverter,
  getFirestore,
  increment,
  limit,
  onSnapshot,
  orderBy,
  Query,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from '@firebase/firestore';
import {
  FirewardGetOutput,
  Installation,
  InstallationNgrokTunnelUrl,
  LicenseKey,
  ShopifyOrder,
  SignUpSurveyResponse,
  SquarespaceOrder,
  StripePrice,
  StripeProduct,
  StripeSubscription,
  StripeTaxRate,
  User as FirestoreUser,
  userIdFromSubcollectionRef,
  UserNotes,
  StripePayment,
} from 'cogsFirestore';
import { fromPairs } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Path } from '../routes';
import { currentUser } from './auth';
import { EMULATOR_HOST } from './environment';
import firebaseApp, { EMULATOR_PORTS, firestoreConsoleUrl } from './firebaseApp';

const firestore = getFirestore(firebaseApp);

if (EMULATOR_HOST) {
  connectFirestoreEmulator(firestore, EMULATOR_HOST, EMULATOR_PORTS.firestore);
}

const userConverter: FirestoreDataConverter<FirestoreUser<FirewardGetOutput>> = {
  fromFirestore(snapshot) {
    return snapshot.data();
  },
  toFirestore(data) {
    return data;
  },
};

const usersRef = collection(firestore, 'users').withConverter(userConverter);

export function userRef(userId: string) {
  return doc(firestore, 'users', userId).withConverter(userConverter);
}

export const userAdminDataCollectionGroup = collectionGroup(firestore, 'admin');

const squarespaceOrdersRef = collection(firestore, 'squarespace_orders');

const shopifyOrdersRef = collection(firestore, 'shopify_orders');

export function useAllUserNotes() {
  const ref = useMemo(() => query(userAdminDataCollectionGroup, where('notes', '>', '')), []);
  const userNotesList = useFirestoreCollection<UserNotes<FirewardGetOutput>>(ref);
  return useMemo(
    () =>
      fromPairs(
        userNotesList?.map(
          ([_, userNotes, ref]) =>
            [userIdFromSubcollectionRef(ref), userNotes] as [string, typeof userNotes]
        )
      ),
    [userNotesList]
  );
}

export function signUpSurveyResponseRef(userId: string) {
  return doc(userRef(userId), 'sign-up-survey', 'response').withConverter<SignUpSurveyResponse>({
    fromFirestore: (snapshot) => snapshot.data() as SignUpSurveyResponse,
    toFirestore: (response: SignUpSurveyResponse) => response,
  });
}

export const signUpSurveyResponsesCollectionGroup = collectionGroup(firestore, 'sign-up-survey');

export function userNotesRef(userId: string) {
  return doc(userRef(userId), 'admin', 'notes').withConverter<UserNotes>({
    fromFirestore: (snapshot) => snapshot.data() as UserNotes,
    toFirestore: (response) => response,
  });
}

export function licenseKeysRef(userId: string) {
  return collection(userRef(userId), 'licenseKeys');
}

export function installationsRef(userId: string) {
  return collection(userRef(userId), 'installations');
}

const installationsCollectionGroup = collectionGroup(firestore, 'installations');

function stripeCogsLicensesRef() {
  return query(
    collection(firestore, 'stripe_products'),
    where('metadata.cogs_license', '>', '')
  ).withConverter<StripeProduct<FirewardGetOutput>>({
    fromFirestore: (snapshot) => snapshot.data() as StripeProduct<FirewardGetOutput>,
    toFirestore: (response) => response,
  });
}

function stripeSubscriptionsRef(userId: string) {
  return collection(userRef(userId), 'subscriptions');
}

const stripeSubscriptionsCollectionGroup = collectionGroup(firestore, 'subscriptions');

export function useAllCogsSubscriptions() {
  return useFirestoreCollection<StripeSubscription<FirewardGetOutput>>(
    stripeSubscriptionsCollectionGroup
  );
}

function stripePaymentsRef(userId: string) {
  return collection(userRef(userId), 'payments');
}

const stripePaymentsCollectionGroup = collectionGroup(firestore, 'payments');

export function useAllCogsPayments() {
  return useFirestoreCollection<StripePayment<FirewardGetOutput>>(
    stripeSubscriptionsCollectionGroup
  );
}
function productPricesRef(productId: string) {
  return query(
    collection(firestore, 'stripe_products', productId, 'prices'),
    where('active', '==', true)
  );
}

const stipeTaxRatesRef = query(
  collection(firestore, 'stripe_products', 'tax_rates', 'tax_rates'),
  where('active', '==', true)
);

export function useCogsInstallations(userId: string | undefined) {
  const ref = useMemo(() => (userId ? installationsRef(userId) : undefined), [userId]);
  return useFirestoreCollection<Installation<FirewardGetOutput>>(ref);
}

export function useAllCogsInstallations() {
  return useFirestoreCollection<Installation<FirewardGetOutput>>(installationsCollectionGroup);
}

export function useCogsLicenseKeys(userId: string | undefined) {
  const ref = useMemo(() => (userId ? licenseKeysRef(userId) : undefined), [userId]);
  return useFirestoreCollection<LicenseKey<FirewardGetOutput>>(ref);
}

const licenseKeysCollectionGroup = collectionGroup(firestore, 'licenseKeys');

export function useAllCogsLicenseKeys() {
  return useFirestoreCollection<LicenseKey<FirewardGetOutput>>(licenseKeysCollectionGroup);
}

export async function addLicenseKey(userId: string, licenseKey: LicenseKey) {
  await addDoc(licenseKeysRef(userId), licenseKey);
}

export async function updateLicenseKey(
  userId: string,
  id: string,
  licenseKey: Partial<LicenseKey>
) {
  await updateDoc(doc(licenseKeysRef(userId), id), licenseKey);
}

export async function deleteLicenseKey(userId: string, id: string, installationId?: string) {
  if (installationId) {
    await unassignLicenseKey(userId, id, installationId);
  }
  await deleteDoc(doc(licenseKeysRef(userId), id));
}

export async function assignLicenseKey(
  userId: string,
  licenseKeyId: string,
  existingLicenseKey: LicenseKey,
  installationId: string
) {
  await writeBatch(firestore)
    .update(doc(licenseKeysRef(userId), licenseKeyId), {
      installationId,
      assignmentCount: increment(
        existingLicenseKey.unverifiedUnassignedInstallationIds?.includes(installationId) ? 0 : 1
      ),
      unverifiedUnassignedInstallationIds: arrayRemove(installationId),
    })
    .update(doc(installationsRef(userId), installationId), { licenseKeyId })
    .commit();
}

export async function unassignLicenseKey(
  userId: string,
  licenseKeyId: string,
  installationId: string
) {
  await writeBatch(firestore)
    .update(doc(licenseKeysRef(userId), licenseKeyId), {
      installationId: deleteField(),
      unverifiedUnassignedInstallationIds: arrayUnion(installationId),
    })
    .update(doc(installationsRef(userId), installationId), {
      licenseKeyId: deleteField(),
    })
    .commit();
}

export async function setInstallationName(userId: string, installationId: string, name: string) {
  await updateDoc(doc(installationsRef(userId), installationId), { name: name || deleteField() });
}

export async function deleteInstallation(userId: string, installationId: string) {
  await deleteDoc(doc(installationsRef(userId), installationId));
}

export function installationFirebaseConsoleUrl(userId: string, installationId: string) {
  return firestoreConsoleUrl(doc(installationsRef(userId), installationId));
}

export function licenseKeyFirebaseConsoleUrl(userId: string, licenseKeyId: string) {
  return firestoreConsoleUrl(doc(licenseKeysRef(userId), licenseKeyId));
}

export function subscriptionFirebaseConsoleUrl(userId: string, subscriptionId: string) {
  return firestoreConsoleUrl(doc(stripeSubscriptionsRef(userId), subscriptionId));
}

export async function setSignUpSurveyResponse(userId: string, howDidYouHearAboutCogs: string) {
  await setDoc(signUpSurveyResponseRef(userId), {
    howDidYouHearAboutCogs,
    created: serverTimestamp(),
  });
}

export function useFirestoreUser(userId: string | undefined) {
  const ref = useMemo(() => (userId ? userRef(userId) : undefined), [userId]);
  return useFirestoreDocument<FirestoreUser>(ref);
}

export function useFirestoreUsers() {
  return useFirestoreCollection<FirestoreUser<FirewardGetOutput>>(usersRef);
}

export function useFirestoreDocument<Item>(ref: DocumentReference | undefined) {
  const [item, setItem] = useState<Item | null>();

  useEffect(() => {
    if (ref) {
      const unsubscribe = onSnapshot(ref, (snapshot) =>
        setItem(snapshot.exists() ? (snapshot.data() as Item) : null)
      );
      return unsubscribe;
    } else {
      setItem(undefined);
    }
  }, [ref]);

  return item;
}

function useFirestoreCollection<Item>(ref: CollectionReference | Query | undefined) {
  const [items, setItems] = useState<[string, Item, DocumentReference][]>();

  useEffect(() => {
    setItems(undefined);
    if (ref) {
      const unsubscribe = onSnapshot(ref, (snapshot) =>
        setItems(snapshot.docs.map((doc) => [doc.id, doc.data() as Item, doc.ref]))
      );
      return unsubscribe;
    } else {
      setItems(undefined);
    }
  }, [ref]);

  return items;
}

function useFirestoreCollections<Item>(refs: [string, CollectionReference | Query][] | undefined) {
  const [items, setItems] = useState<{ [id: string]: [string, Item, DocumentReference][] }>();

  useEffect(() => {
    if (!refs) {
      setItems(undefined);
      return;
    }

    setItems({});
    const unsubscribes = refs.map(([id, ref]) => {
      const unsubscribe = onSnapshot(ref, (snapshot) =>
        setItems((items) => ({
          ...items,
          [id]: snapshot.docs.map((doc) => [doc.id, doc.data() as Item, doc.ref]),
        }))
      );
      return unsubscribe;
    });
    return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
  }, [refs?.length]); // FIXME: For some reason having a dependency on the `refs` array here causes an infinite loop

  return items;
}

export function useAllStripeLicenseProducts() {
  const ref = useMemo(() => stripeCogsLicensesRef(), []);
  const products = useFirestoreCollection<StripeProduct<FirewardGetOutput>>(ref);
  return products
    ?.filter(([, product]) => product.metadata?.cogs_license)
    ?.sort(
      ([, productA], [, productB]) =>
        (productA.metadata?.sort_order ? parseInt(productA.metadata?.sort_order) : Infinity) -
        (productB.metadata?.sort_order ? parseInt(productB.metadata?.sort_order) : Infinity)
    );
}

export function useProductPrices(productId: string | undefined) {
  const ref = useMemo(() => (productId ? productPricesRef(productId) : undefined), [productId]);
  return useFirestoreCollection<StripePrice<FirewardGetOutput>>(ref);
}

export function useMultipleProductPrices(productIds: string[] | undefined) {
  const refs = useMemo(
    () => productIds?.map((id): [string, Query] => [id, productPricesRef(id)]),
    [productIds]
  );
  return useFirestoreCollections<StripePrice<FirewardGetOutput>>(refs);
}

export async function createCheckoutUrl(
  userId: string,
  mode: 'payment' | 'subscription',
  items: { price: string; quantity: number; tax_rates?: string[]; description?: string }[]
): Promise<string> {
  const ref = await addDoc(collection(userRef(userId), 'checkout_sessions'), {
    mode,
    line_items: items,
    success_url: window.location.origin + Path.Licenses,
    cancel_url: window.location.href,
    automatic_tax: { enabled: true },
  });

  return await new Promise<string>((resolve, reject) => {
    const unsubscribe = onSnapshot(
      ref,
      async (
        snap: DocumentSnapshot<{
          url?: string;
          error?: { message: string };
        }>
      ) => {
        const { url, error } = snap.data() ?? {};
        if (error) {
          unsubscribe();
          reject(new Error(error.message));
        } else if (url) {
          unsubscribe();
          resolve(url);
        }
      }
    );
  });
}

export function useStripeSubscriptions(userId: string | undefined) {
  const ref = useMemo(() => (userId ? stripeSubscriptionsRef(userId) : undefined), [userId]);
  return useFirestoreCollection<StripeSubscription<FirewardGetOutput>>(ref);
}

export function useStripeOneOffPayments(userId: string | undefined) {
  // TODO: filter one-off payments only
  const ref = useMemo(() => (userId ? stripePaymentsRef(userId) : undefined), [userId]);
  return useFirestoreCollection<StripePayment<FirewardGetOutput>>(ref);
}

export function useTaxRates() {
  return useFirestoreCollection<StripeTaxRate<FirewardGetOutput>>(stipeTaxRatesRef);
}

export function useSignUpSurveyResponses(from: number, max = 10) {
  const ref = useMemo(
    () =>
      query(
        signUpSurveyResponsesCollectionGroup,
        orderBy('created', 'desc'),
        ...(from !== -Infinity ? [endAt(new Date(from))] : []),
        limit(max)
      ),
    [from, max]
  );
  const responses = useFirestoreCollection<SignUpSurveyResponse<FirewardGetOutput>>(ref);
  return useMemo(
    () => responses?.map(([, response, ref]) => ({ userId: ref.parent.parent!.id, response })),
    [responses]
  );
}

export function useUserNotes(userId: string | undefined) {
  const ref = useMemo(() => (userId ? userNotesRef(userId) : undefined), [userId]);
  return useFirestoreDocument<UserNotes<FirewardGetOutput>>(ref);
}

export async function setUserNotes(userId: string, notes: string) {
  const updatedBy = currentUser()?.uid ?? '';
  const ref = userNotesRef(userId);
  await setDoc(ref, { notes, updated: serverTimestamp(), updatedBy });
}

export async function setUserDiscordUsername(userId: string, discordUsername: string) {
  const ref = userRef(userId);
  await setDoc(
    ref,
    { discordUsername: discordUsername ? discordUsername : deleteField() },
    { merge: true }
  );
}

export async function setFirstPhoneCall(userId: string, date: Date | null) {
  const ref = userRef(userId);
  await setDoc(ref, { firstPhoneCall: date ? date : deleteField() }, { merge: true });
}

export function useAllSquarespaceOrders() {
  const ref = useMemo(() => query(squarespaceOrdersRef, orderBy('createdOn', 'desc')), []);
  return useFirestoreCollection<SquarespaceOrder<FirewardGetOutput>>(ref);
}

export function useAllShopifyOrders() {
  const ref = useMemo(() => query(shopifyOrdersRef, orderBy('created_at', 'desc')), []);
  return useFirestoreCollection<ShopifyOrder>(ref);
}

export async function addStoreOrderEmailAddress(userId: string, email: string) {
  const ref = userRef(userId);
  await setDoc(ref, { storeEmailAddresses: arrayUnion(email) }, { merge: true });
}

export async function removeStoreOrderEmailAddress(userId: string, email: string) {
  const ref = userRef(userId);
  await setDoc(ref, { storeEmailAddresses: arrayRemove(email) }, { merge: true });
}

export async function removeAllStoreOrderEmailAddress(userId: string) {
  const ref = userRef(userId);
  await setDoc(ref, { storeEmailAddresses: deleteField() }, { merge: true });
}

const installationNgrokTunnelConverter: FirestoreDataConverter<
  InstallationNgrokTunnelUrl<FirewardGetOutput>
> = {
  fromFirestore(snapshot) {
    return snapshot.data() as InstallationNgrokTunnelUrl<FirewardGetOutput>;
  },
  toFirestore(data) {
    return data;
  },
};

function ngrokTunnelUrlRef(userId: string, installationId: string) {
  return doc(installationsRef(userId), installationId, 'ngrok-tunnel', 'url').withConverter(
    installationNgrokTunnelConverter
  );
}

export function useNgrokTunnelUrl(userId: string | undefined, installationId: string | undefined) {
  const ref = useMemo(
    () => (userId && installationId ? ngrokTunnelUrlRef(userId, installationId) : undefined),
    [userId, installationId]
  );
  const tunnel = useFirestoreDocument<InstallationNgrokTunnelUrl<FirewardGetOutput>>(ref);
  if (tunnel === null) {
    return null;
  }
  if (tunnel) {
    const url = new URL(tunnel.url);
    url.username = tunnel.username;
    url.password = tunnel.password;
    return url.toString();
  }
}
